import React, { useState } from 'react';
// import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useTodoContext } from '../../utils/GlobalState';
import API from '../../utils/API';





function Copyright() {

    return (
        <Typography variant='body2' color='textSecondary' align='center'>
            {'Copyright © '}
            <Link color='inherit' href='https://material-ui.com/'>
                GetPland
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function SignUp(props) {
    const { onSuccess } = props

    const { firebase } = useTodoContext();

    const classes = useStyles();
    // const { classes } = props
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [displayName, setdisplayName] = useState('')
    const [firstName, setfirstName] = useState('')
    const [lastName, setlastName] = useState('')
    const [uniqueUsr, setuniqueUsr] = useState(false)
    // const history = useHistory()
    const regex = RegExp(/^[A-Za-z\d-_]+$/);

    async function onRegister() {
        try {
            await firebase.register(displayName, firstName, lastName, email, password).then(res => console.log(res))
            await firebase.login(email, password)
            // history.push('/events')
            onSuccess()
        } catch (error) {
            alert(error.message)
        }
    }

    const checkUserName = () => {
        console.log(displayName);
        if (displayName) {

            API.findUserName(displayName).then((res) => {
                console.log(res.data);
                if (res.data.userFound) {
                    setuniqueUsr(true)
                } else {
                    setuniqueUsr(false)
                }
            })
        }
    }


    return (
        <Container maxWidth='xs'>
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component='div' variant='h5'>
                    Sign up
        </Typography>
                <form className={classes.form} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete='fname'
                                onBlur={e => checkUserName()}
                                error={!regex.test(displayName) || uniqueUsr}
                                helperText={
                                    (!regex.test(displayName)) ? 'Only Letters, Numbers, Dashes, and Underscores are allowed.' : ''
                                        || (uniqueUsr) ? 'Username already in use. Please select a new one.' : ''
                                }
                                name='displayName'
                                variant='outlined'
                                required
                                fullWidth
                                id='displayName'
                                label='Display Name'
                                autoFocus
                                value={displayName}
                                onChange={e => setdisplayName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                name='firstName'
                                variant='outlined'
                                required
                                fullWidth
                                id='firstName'
                                label='First Name'
                                value={firstName}
                                onChange={e => setfirstName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                name='lastName'
                                variant='outlined'
                                required
                                fullWidth
                                id='lastName'
                                label='Last Name'
                                value={lastName}
                                onChange={e => setlastName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant='outlined'
                                required
                                fullWidth
                                id='email'
                                label='Email Address'
                                name='email'
                                autoComplete='email'
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant='outlined'
                                required
                                fullWidth
                                name='password'
                                label='Password'
                                type='password'
                                id='password'
                                autoComplete='current-password'
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value='allowExtraEmails' color='primary' />}
                label='I want to receive inspiration, marketing promotions and updates via email.'
              />
            </Grid> */}
                    </Grid>
                    <Button
                        // type='submit'
                        fullWidth
                        variant='contained'
                        color='primary'
                        className={classes.submit}
                        onClick={onRegister}
                    >
                        Sign Up
          </Button>
                    {/* <Grid container justify='flex-end'>
                        <Grid item>
                            <Link href='/login' variant='body2'>
                                Already have an account? Sign in
              </Link>
                        </Grid>
                    </Grid> */}
                </form>
            </div>
            <Box mt={5}>
                <Copyright />
            </Box>
        </Container>
    );
}