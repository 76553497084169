import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid';
import EvtCard from '../EventCards'
// import { useEffect } from 'react';
import API from '../../utils/API'
import { useTodoContext, } from '../../utils/GlobalState';
import { Card, CardContent, CardActions } from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));


export default function expPanel() {
    const classes = useStyles();
    const [expanded, setExpanded] = useState('panel1');
    const [events, setEvents] = useState([])
    const [guestEvents, setguestEvents] = useState([])
    const [pastEvents, setpastEvents] = useState([])
    const { uid } = useTodoContext();
    const history = useHistory();

    useEffect(() => {
        if (uid) {
            getAllData()
        }
    }, [uid])

    const getAllData = async () => {
        console.log('get events:', uid);
        try {
            const ownedEvents = await API.getUsersEvents(uid)
            const guestEvents = await API.getGuestEvents(uid)
            const [cleanOwned, dirtyOwned] = checkIfEventExpired(ownedEvents)
            const [cleanGuest, dirtyGuest] = checkIfEventExpired(guestEvents)
            setEvents(cleanOwned)
            setguestEvents(cleanGuest)
            setpastEvents([...dirtyOwned, ...dirtyGuest])

        } catch (error) {
            console.log(error)
        }
    }

    const checkIfEventExpired = e => {
        const cleanEvents = []
        const tempEvents = []
        // check all owned and guest events if the endDate is in the past
        e.data.forEach(oEvent => {
            // compare dates to see if event is in the past
            // negative number is in the future, positive is in the past. 
            // console.log(moment().diff(moment(oEvent.endDate), "minutes"));
            const timeDiff = moment().diff(moment(oEvent.endDate), "minutes")
            timeDiff > 0 ? tempEvents.push(oEvent) : cleanEvents.push(oEvent);
            // if date is in past, add to 
        })
        return [cleanEvents, tempEvents]
    }

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const handleClick = (e) => {
        console.log(e);
        history.push(`/${e}`)
    }

    return (
        <div className={classes.root}>
            <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1a-content'
                    id='panel1a-header'
                >
                    <Typography className={classes.heading}>My Events</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid container className={classes.root} spacing={1}>
                        {(events[0]) ? events.map((evt, index) =>
                            <EvtCard
                                key={evt._id}
                                title={evt.title}
                                description={evt.description}
                                location={evt.location}
                                start={evt.startDate}
                                end={evt.endDate}
                                id={evt._id}
                            />
                        ) :
                            <Card className={classes.root}>
                                <CardContent>
                                    <Typography variant='h5' gutterBottom>
                                        You don't have any active Events! 😭
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        onClick={() => { handleClick('NewEvent') }}
                                    >Click Here and Create A New Event!</Button>
                                </CardActions>
                            </Card>
                        }
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel2a-content'
                    id='panel2a-header'
                >
                    <Typography className={classes.heading}>You're Invited To...</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid container className={classes.root} spacing={1}>
                        {(guestEvents[0]) ? guestEvents.map((evt, index) =>
                            (evt.owner != uid) ?
                                <EvtCard
                                    key={evt._id}
                                    title={evt.title}
                                    description={evt.description}
                                    location={evt.location}
                                    start={evt.startDate}
                                    end={evt.endDate}
                                    id={evt._id}
                                /> : ''

                        ) :
                            <Card className={classes.root}>
                                <CardContent>
                                    <Typography variant='h5' gutterBottom>
                                        You don't haven't been invited to any events! 😭
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        onClick={() => { handleClick('NewEvent') }}
                                    >Click Here and Create A New Event!</Button>
                                </CardActions>
                            </Card>
                        }
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel2a-content'
                    id='panel2a-header'
                >
                    <Typography className={classes.heading}>Past Events</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid container className={classes.root} spacing={1}>
                        {(pastEvents[0]) ? pastEvents.map((evt, index) =>
                            <EvtCard
                                key={evt._id}
                                title={evt.title}
                                description={evt.description}
                                location={evt.location}
                                start={evt.startDate}
                                end={evt.endDate}
                                id={evt._id}
                            />
                        ) :
                            <Card className={classes.root}>
                                <CardContent>
                                    <Typography variant='h5' gutterBottom>
                                        You don't have any past Events!
                                    </Typography>
                                </CardContent>
                            </Card>
                        }
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div >
    );
}
