import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import EventInfo from '../components/EventInfo'
import TaskList from '../components/TaskList'
import API from '../utils/API'
import GuestList from '../components/GuestList';
import Agenda from '../components/Agenda';
import { Grid, CircularProgress } from '@material-ui/core';
// import FileCopyIcon from '@material-ui/icons/FileCopy';
import InvFriend from '../components/InvFriend'
import { useTodoContext } from '../utils/GlobalState';
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(),
    width: '100%'
  },
}));

export default function Event({ match }) {
  console.log('Event Detail Loading');

  const { userAuth } = useTodoContext();
  const eventId = match.params.id
  const classes = useStyles();

  const [eventData, seteventData] = useState('Loading')
  const history = useHistory();
  // const [taskData, settaskData] = useState(false)
  // const [admin, setAdmin] = useState(false)
  // console.log({ eventData });

  useEffect(() => {
    API.getEvent(eventId)
      .then(res => seteventData(res.data))
      .catch(err => {
        console.log(`error`, { err });
        throw new Error(err);
      })
  }, [])
  useEffect(() => {
    if (userAuth) {
      console.log('passed');
    } else {
      history.push('/login')
    }
  }, [userAuth])



  // console.log(eventData);
  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12}>
          <Grid container justify="center" spacing={0}>
            <Grid item xs={12} md={7}>
              {
                (eventData === 'Loading') ?
                  <CircularProgress />
                  :
                  <EventInfo
                    eventData={eventData}
                    updateData={API.updateEvent}
                    seteventData={seteventData}
                  />
              }
              {
                (eventData === 'Loading') ?
                  <CircularProgress />
                  :
                  ((eventData.selectedWidgets.indexOf('task') >= 0) &&
                    <TaskList
                      eventData={eventData}
                    />
                  )
              }
            </Grid>
            <Grid item xs={12} md={5}>
              <Grid item xs={12}>
                {
                  (eventData === 'Loading') ?
                    <CircularProgress />
                    :
                    <GuestList
                      eventData={eventData}
                    >
                      <InvFriend
                        eventData={eventData}
                      />
                    </GuestList>
                }
              </Grid>
              <Grid item xs={12}>
                {
                  (eventData === 'Loading') ?
                    <CircularProgress />
                    :
                    ((eventData.selectedWidgets.indexOf('agenda') >= 0) &&
                      <Agenda
                        eventData={eventData}
                      />
                    )
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </main >
  )
}
