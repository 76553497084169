import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import MaterialTable from 'material-table';
import icons from './tableIcons';
import API from '../../utils/API'
import moment from 'moment'
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import DoneIcon from '@material-ui/icons/Done';
import { useTodoContext, } from '../../utils/GlobalState';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

export default function TaskList(props) {
  console.log('TaskList Loading ****^(*&^(*&^(*&^(&*^(&*^');
  console.log(props);


  const classes = useStyles();
  const [eventData] = useState(props.eventData)
  const [evt] = useState(props.eventData._id)
  // const [ownr, setownr] = useState(props.owner)
  const [guests, setguests] = useState([])
  // const [evtData, setevtData] = useState(props.eventData)
  const [taskData, setTaskData] = useState()
  // const [admin, setadmin] = useState(false)
  const { uid, userInfo } = useTodoContext();
  // const assignedToDropDown = props.guests.map(guest => {
  //   console.log(guest.userName);
  // })

  // const [update, setUpdate] = useState(false)

  const handleAdd = (data) => {
    API.saveTask({
      task: data.task,
      eventid: data.eventid,
      status: data.status,
      assignedTo: data.assignedTo,
      dueDate: moment(data.dueDate).format('MM/DD/YYYY')
    }).then(updateTable)
  };
  // console.log(evt);
  const handleDelete = (data) => {
    // console.log(data);
    API.deleteTask(data._id).then(updateTable)
  };
  const updateTable = () => {
    API.getEventTasks(evt)
      .then(
        res => setTaskData(res.data),
        // console.log('guests', props.guests),
        // console.log(taskData),
      )
      .catch(err => {
        console.log(`error`, { err });
        // throw new Error(err);
      })
  };
  const updateTask = (data) => {
    API.updateTask(data._id, data).then(updateTable)
  }
  useEffect(() => {
    console.log(evt);

    if (evt) {
      API.getEventTasks(evt)
        .then(
          res => setTaskData(res.data),
          console.log('guests', props.guests),
          // res => console.log(res.data),
          // console.log(taskData),
        )
        .catch(err => {
          console.log(`error`, { err });
          // throw new Error(err);
        })
    }
  }, [])

  useEffect(() => {
    let gst = []
    eventData.guests.map(guest => {
      // console.log(guest.userName);
      gst.push(guest.userName)
    })
    console.log(gst);

    setguests(gst)
  }, [])

  // useEffect(() => {
  //   if (ownr === uid) {
  //     console.log(true);
  //   }
  //   console.log(ownr, uid);

  // }, [taskData])

  const [state, setState] = useState({
    columns: [
      { title: 'Task', field: 'task' },
      // { title: 'Status', field: 'status', initialEditValue: 'New', lookup: { 'New': 'New', 'Started': 'Started', 'InProgress': 'In Progress', 'Done': 'Done' } },
      { title: 'Assigned To', field: 'assignedTo' },
      { title: 'Due Date', field: 'dueDate', type: 'date' },
    ]
  });

  return (
    <div className={classes.content}>
      <Paper >
        <MaterialTable
          title='Task List'
          icons={icons}
          columns={state.columns}
          data={taskData}
          // options={{
          //   grouping: true
          // }}
          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  newData.eventid = evt
                  handleAdd(newData)
                  resolve();
                }, 600);
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  if (oldData) {
                    updateTask(newData)
                  }
                  resolve();
                }, 600);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  handleDelete(oldData)
                  resolve();
                }, 600);
              }),
          }}
          actions={[
            {
              icon: icons.Check,
              tooltip: 'Done!',
              onClick: (event, rowData) => {
                // Do save operation
                const result = rowData.whoCompleted.find(({ firstName }) => firstName === userInfo.firstName)
                console.log(rowData, result);
                const who = { uid: uid, firstName: userInfo.firstName }
                // save in object who's clicked
                if (result === undefined) {
                  API.completeTask(rowData._id, who).then(updateTable)
                }
              }
            }
          ]}
          detailPanel={rowData => {
            const guestCompleted = rowData.whoCompleted
            return (
              <div className={classes.root}>
                {guestCompleted.map((guest, index) => (
                  <Chip
                    icon={<FaceIcon />}
                    // avatar={<Avatar>`${rowData.assigedTo[0]}`</Avatar>}
                    label={`${guest.firstName} Completed This Task!`}
                    color="primary"
                    // onDelete={handleDelete}
                    deleteIcon={<DoneIcon />}
                  />
                ))}
              </div>
            )
          }}
          options={{
            actionsColumnIndex: -1
          }}
        />
      </Paper>
    </div>
  );
}
