import React, { useState, useEffect } from 'react';
// import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// import firebase from '../utils/firebase'
import { useTodoContext } from '../../utils/GlobalState';

function Copyright() {
    return (
        <Typography variant='body2' color='textSecondary' align='center'>
            {'Copyright © '}
            <Link color='inherit' href='https://material-ui.com/'>
                GetPland
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    highligted: {
        color: theme.palette.info
    }
}));

export default function SignIn(props) {
    const { onSuccess } = props

    const classes = useStyles();
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    // const history = useHistory()
    const { userAuth, firebase } = useTodoContext();
    const [open, setOpen] = React.useState(false);
    const [message, setmessage] = useState()
    const [type, settype] = useState()

    const handleClick = (m, t) => {
        console.log(m, t);

        setmessage(m)
        settype(t)
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    useEffect(() => {
        if (userAuth) {
            // history.push('/events')
            onSuccess()
        }
    }, [userAuth])


    const resetPassword = (e) => {
        e.preventDefault()
        console.log('reset password!');
        if (email) {
            firebase.auth.sendPasswordResetEmail(email).then(function () {
                handleClick(`Email sent to ${email}! Check your email!`, 'success')
            }).catch(function (error) {
                // An error happened.
            });
        } else {
            handleClick('Enter your email first!', 'error')
        }
    }
    async function login() {
        try {
            await firebase.login(email, password)
        } catch (error) {
            if (error.code === 'auth/user-not-found') {
                // alert('User Not Found! Please Sign Up To Use GetPland!')
                handleClick(error.message, 'error')
            } else {
                // alert(error.message)
                handleClick(error.message, 'error')
                // console.log(error);
            }
        }
    }
    return (
        <Container maxWidth='xs'>
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component='div' variant='h5'>
                    Sign in
        </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        id='email'
                        label='Email Address'
                        name='email'
                        autoComplete='email'
                        autoFocus
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                    <TextField
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        name='password'
                        label='Password'
                        type='password'
                        id='password'
                        autoComplete='current-password'
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                    <FormControlLabel
                        control={<Checkbox value='remember' color='primary' />}
                        label='Remember me'
                    />
                    <Button
                        // type='submit'
                        fullWidth
                        variant='contained'
                        color='primary'
                        className={classes.submit}
                        onClick={login}
                    >
                        Sign In
          </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link href='#' onClick={resetPassword} variant=' body2'>
                                Forgot password? Enter E-Mail above and click here!
              </Link>
                        </Grid>
                        {/* <Grid item>
                            <Link href='/signup' variant='body2'>
                                {'Don't have an account? Sign Up'}
                            </Link>
                        </Grid> */}
                    </Grid>
                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message={message}
                severity={type}
                variant='filled'
                action={
                    <React.Fragment>
                        {/* <Button color="secondary" size="small" onClick={handleClose}>
                            UNDO
            </Button> */}
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </Container >
    );
}