import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/firebase-firestore'
import API from './API'
import moment from 'moment'

var firebaseConfig = {
    apiKey: "AIzaSyDkmIaYNZE0iOcspH2lTvBgZ38frY0lHxA",
    authDomain: "pland-f2478.firebaseapp.com",
    databaseURL: "https://pland-f2478.firebaseio.com",
    projectId: "pland-f2478",
    storageBucket: "pland-f2478.appspot.com",
    messagingSenderId: "922931716272",
    appId: "1:922931716272:web:345b1b36619742796c5b75",
    measurementId: "G-DS0H8NW57J"
};
// Initialize Firebase


class Firebase {
    constructor() {
        app.initializeApp(firebaseConfig);
        // app.analytics();
        // console.log('firebase constructor');

        this.auth = app.auth()
        this.db = app.firestore()
    }
    login(email, password) {
        console.log("logging in");

        return this.auth.signInWithEmailAndPassword(email, password).then(() => {
            // console.log(this.auth);
        }).catch((err) => {
            alert(err.message)
            // if (err.code === 'auth/user-not-found') {
            //     alert("User Not Found! Please Sign Up To Use GetPland!")
            // } else {

            //     console.log(err);
            // }

        })
    }
    logout() {
        return this.auth.signOut()
    }
    async register(displayName, firstName, lastName, email, password) {
        console.log("registering", displayName);

        await this.auth.createUserWithEmailAndPassword(email, password).then(() => {
            app.auth().currentUser.sendEmailVerification()
            // console.log('start mongo', app.auth().currentUser.uid);
            API.saveUser({
                uid: app.auth().currentUser.uid,
                userName: displayName,
                nameLower: displayName.toLowerCase(),
                firstName: firstName,
                lastName: lastName,
                email: email,
                created: moment()
            })
            // console.log('end mongo');

        })
        return this.auth.currentUser.updateProfile({
            displayName: displayName,
            firstName: firstName,
            lastName: lastName
        })


    }
    isInitialized() {
        return new Promise(resolve => {
            this.auth.onAuthStateChanged(resolve)
        })
    }
    getCurrentUsername() {
        console.log(this.auth.currentUser);
        return this.auth.currentUser
    }
    getCurrentUid() {
        console.log(this.auth.currentUser);

        // return this.auth.currentUser
    }
    onAuthStateChange(cb) {
        return this.auth.onAuthStateChanged(cb);
    }
}

export default new Firebase()