import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import Detail from './pages/Detail';
import NoMatch from './pages/NoMatch';
import Login from './pages/Login'
import { createMuiTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import LeftNav from './components/LeftNav'
import PlandAppBar from './components/PlandAppBar'
import EventDetail from './pages/EventDetail'
import Events from './pages/Event'
import NewEvent from './pages/NewEvent'
import Invited from './pages/Invited'
// import firebase from './utils/firebase'
import { TodoProvider } from './utils/GlobalState';
import API from './utils/API';



const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({


  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('lg')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function App() {
  const classes = useStyles();
  // const [firebaseInitialized, setfirebaseInitialized] = useState(false)
  // useEffect(() => {
  //   firebase.isInitialized().then(val => {
  //     setfirebaseInitialized(val)
  //   })
  // })
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [themeTF, setthemeTF] = React.useState(false)
  const palletType = themeTF ? 'dark' : 'light';
  // const { uid, userInfo } = useTodoContext();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const theme = createMuiTheme({
    palette: {
      // primary: {
      //   light: '#ca73ab',
      //   main: '#4a5868',
      //   dark: '#A2cced',
      //   contrastText: '#fff'
      // },
      // secondary: {
      //   light: '',
      //   main: '#ca73ab',
      //   dark: '',
      //   contrastText: ''
      // },
      type: palletType,
    },
  });
  const handleThemeChange = (event) => {
    // console.log(event.target);
    // let ld = theme.palette.type
    // if (ld === 'dark') {
    // theme.pallette.type = 'light'
    // setthemeTF(false)
    // } else {
    // theme.pallette.type = 'dark'
    // setthemeTF(true)
    // }
    // console.log(uid, { theme: !themeTF })
    // API.updateUserTheme(uid, { theme: !themeTF })
    setthemeTF(!themeTF)
  }

  return (
    <Router>
      <TodoProvider>
        <ThemeProvider theme={theme}>
          <div className={classes.root}>
            <CssBaseline />
            <PlandAppBar
              handleDrawerToggle={handleDrawerToggle}
              classes={classes}
              themeChange={handleThemeChange}
              ld={themeTF}
            />
            <LeftNav
              handleDrawerToggle={handleDrawerToggle}
              mobileOpen={mobileOpen}
            />
            <div className={classes.toolbar} />
            <Switch>
              <Route exact path={['/login']}>
                <Login />
              </Route>
              <Route exact path={['/', '/events']}>
                <Events />
              </Route>
              <Route exact path={['/NewEvent']}>
                <NewEvent />
              </Route>
              <Route exact path={['/testiong']}>
                <NewEvent />
              </Route>
              <Route path='/event/:id' component={EventDetail} />
              <Route path='/invite/:id' component={Invited} />
              <Route>
                <NoMatch />
              </Route>
            </Switch>
          </div>
        </ThemeProvider>
      </TodoProvider>
    </Router>
  );
}

export default App;
