import React, { createContext, useContext, useState, useEffect } from 'react';
import firebase from './firebase'
import API from './API'


const TodoContext = createContext({
    userAuth: false
});
const { Provider } = TodoContext;


function TodoProvider({ value = [], ...props }) {
    // console.log('GlobalState Loading');

    const [userAuth, setuserAuth] = useState(false)
    const [userEmail, setuserEmail] = useState('')
    const [uid, setUid] = useState('')
    const [emailVerf, setemailVerf] = useState(false)
    // const [eLink, seteLink] = useState(false)
    const [userInfo, setuserInfo] = useState({})

    useEffect(() => {
        if (uid) {
            console.log('getting user info!');

            API.findFbUid(uid)
                .then(
                    // res => setuserInfo(res.data)
                    res => setuserInfo({
                        firstName: res.data[0].firstName,
                        lastName: res.data[0].lastName,
                        userName: res.data[0].userName,
                        friendsList: res.data[0].friendsList,
                        pendingFriendReq: res.data[0].pendingFriendReq,
                    })
                )
                .catch(err => {
                    console.log(`error`, { err });
                    // console.log(uid);
                    // throw new Error(err)
                })
        }
    }, [uid])

    const authChangeCB = user => {
        // console.log('authChangeCB', user);

        if (user) {
            // console.log('The user is logged in', { user });
            // console.log(user.emailVerified);
            setemailVerf(user.emailVerified)
            // setuserInfo(prevState => ({ ...prevState, userName: user.userName }))
            // setuserInfo()
            setuserEmail(user.email);
            setUid(user.uid);
            setuserAuth(true);

        } else {
            // console.log('The user is not logged in');
            setuserAuth(false)
            setemailVerf('')
            setUid('')
            setuserEmail('')
            setuserInfo('')
        }
    }

    firebase.onAuthStateChange(authChangeCB)


    // Referral Link
    const [refLink, setrefLink] = useState()


    return <Provider value={{ userAuth, firebase, uid, userEmail, userInfo, refLink, setrefLink, emailVerf }} {...props} />;
}

function useTodoContext() {
    return useContext(TodoContext);
}

export { TodoProvider, useTodoContext };