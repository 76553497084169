import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment'
import { CardHeader, CardContent, Typography, Card, Paper, Grid } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Moment from 'moment'
import { DateTimePicker } from "@material-ui/pickers";
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
}));

export default function EventInfo(props) {
    const { updateData, eventData, seteventData } = props
    const {
        title,
        startDate,
        endDate,
        description,
        location,
        eventName,
        _id
    } = eventData
    const classes = useStyles();
    // console.log(props.guests);
    console.log('event info Loading');
    console.log({ eventData });


    const [newEventName, seteventName] = useState(title);
    const [eventDescription, seteventDescription] = useState(description);
    const [eventLocation, seteventLocation] = useState(location);
    const [selectedStartDate, handleStartDateChange] = useState(startDate);
    const [selectedEndDate, handleEndDateChange] = useState(endDate);


    const eventNameChange = (event) => {
        seteventName(event.target.value);
    };
    const eventDescChange = (event) => {
        seteventDescription(event.target.value);
    };
    const eventLocationChange = (event) => {
        seteventLocation(event.target.value);
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        handleClose()
        // input verification
        updateData(_id, {
            title: newEventName,
            description: eventDescription,
            location: eventLocation,
            startDate: selectedStartDate,
            endDate: selectedEndDate
        })
            .then(res => {
                // If success update parent state
                console.log(res)
                seteventData({
                    ...eventData,
                    title: newEventName,
                    description: eventDescription,
                    location: eventLocation,
                    startDate: selectedStartDate,
                    endDate: selectedEndDate,

                })
            })
            .catch(err => console.error(err))


    }

    return (
        <div className={classes.content}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper>
                        <Card>
                            <CardHeader

                                title={`Event: ${props.eventData.title}`}
                                subheader={`Event Start: ${moment(props.eventData.startDate).format("MM/DD/YYYY hh:mm a")} Event End: ${moment(props.eventData.endDate).format("MM/DD/YYYY hh:mm a")}`}
                                action={
                                    <IconButton aria-label="settings" onClick={handleClickOpen}>
                                        <EditIcon />
                                    </IconButton>
                                }
                            />
                            <CardContent>
                                <Typography
                                    variant={"h6"}
                                    gutterBottom
                                >
                                    {`Description:`}
                                </Typography>
                                <Typography>
                                    {props.eventData.description}
                                </Typography>
                                <Typography
                                    variant={"h6"}
                                    gutterBottom
                                >
                                    {`Location:`}
                                </Typography>
                                <Typography>
                                    {props.eventData.location}
                                </Typography>
                                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                                    <DialogTitle id="form-dialog-title">Edit Event Details</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                            Don't Forget To Click Save!
                                        </DialogContentText>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="standard-full-width"
                                                    label="Event Name"
                                                    error={title === undefined || title === ''}
                                                    name="title"
                                                    style={{ margin: 8 }}
                                                    placeholder="Enter your event name here!"
                                                    helperText="Ex: My Super Awesome-tacular Camping Trip!"
                                                    fullWidth
                                                    required
                                                    margin="normal"
                                                    value={newEventName}
                                                    onChange={eventNameChange}
                                                // InputLabelProps={{
                                                //     shrink: true,
                                                // }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Event Description"
                                                    error={description === undefined || description === ''}
                                                    // id="margin-none"
                                                    name="description"
                                                    placeholder="Enter your event description here!"
                                                    // className={classes.textField}
                                                    fullWidth
                                                    multiline
                                                    required
                                                    value={eventDescription}
                                                    style={{ margin: 8 }}
                                                    onChange={eventDescChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Event Location"
                                                    error={location === undefined || location === ''}
                                                    // id="margin-none"
                                                    name="location"
                                                    placeholder="Enter your event description here!"
                                                    // className={classes.textField}
                                                    fullWidth
                                                    multiline
                                                    required
                                                    value={eventLocation}
                                                    style={{ margin: 8 }}
                                                    onChange={eventLocationChange}
                                                />
                                            </Grid>

                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <Grid item xs={12} md={6} lg={6}>
                                                    <DateTimePicker
                                                        // orientation="landscape"
                                                        variant="inline"
                                                        // id="margin-none"
                                                        required
                                                        autoOk
                                                        minDate={new Date()}
                                                        label="Start Date/Time"
                                                        value={selectedStartDate}
                                                        onChange={handleStartDateChange}
                                                        style={{ margin: 8, width: '100%' }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6} lg={6}>
                                                    <DateTimePicker
                                                        variant="inline"
                                                        // id="margin-none"
                                                        required
                                                        autoOk
                                                        // minDate={selectedStartDate || new Date()}
                                                        label="End Date/Time"
                                                        value={selectedEndDate || selectedStartDate}
                                                        onChange={handleEndDateChange}
                                                        style={{ margin: 8, width: '100%' }}
                                                    />
                                                </Grid>

                                            </MuiPickersUtilsProvider>

                                        </Grid>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleClose} color="primary">
                                            Cancel
                                        </Button>
                                        <Button onClick={handleSubmit} color="primary">
                                            Save
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </CardContent>
                        </Card>
                    </Paper>
                </Grid>

            </Grid>
        </div>
    );
}
