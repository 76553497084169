import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
// import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ExpPanel from '../components/ExpansionPanel'
// import firebase from '../utils/firebase'
import { useTodoContext } from "../utils/GlobalState";

const useStyles = makeStyles((theme) => ({
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));


export default function Event() {
  const { userAuth } = useTodoContext();

  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    if (userAuth) {
      console.log('passed');
    } else {
      history.push('/login')
    }
  }, [userAuth])

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <ExpPanel />
    </main>
  )
}
