import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import WbIncandescentTwoToneIcon from '@material-ui/icons/WbIncandescentTwoTone';
import Brightness4TwoToneIcon from '@material-ui/icons/Brightness4TwoTone';
import Box from '@material-ui/core/Box';


const useStyles = makeStyles((theme) => ({
    appBar: {

    },
    toolbarIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        ...theme.mixins.toolbar
    },
}));

function PlandAppBar(props) {
    const { handleDrawerToggle, classes } = props;
    // const [state, setState] = React.useState({
    //     checkedA: false,
    // });
    // const handleChange = (event) => {
    //     console.log(event.target);

    //     setState({ ...state, [event.target.name]: event.target.checked });
    //     console.log(state);
    //     // props.handleChange(state)

    // };
    return (

        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
                <Grid
                    justify='space-between'
                    container
                    spacing={2}>
                    <Grid item>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Grid>
                    <Box display="flex" alignItems="center">
                        <Typography variant="h6" noWrap>
                            {<img height='15px' src={`${process.env.PUBLIC_URL}/assets/font-small.png`} />}
                        </Typography>
                    </Box>
                    <Grid item>
                        {/* <WbIncandescentTwoToneIcon /> */}
                        <Switch
                            checked={props.ld}
                            onChange={props.themeChange}
                            name="themeChange"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                        <Brightness4TwoToneIcon style={{verticalAlign: "middle"}}/>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>

    );
}

export default PlandAppBar;