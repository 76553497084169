import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
// import EventInfo from '../components/EventInfo'
// import TaskList from '../components/TaskList'
import API from '../utils/API'
// import GuestList from '../components/GuestList';
import { Grid, Paper, Card, CardHeader, Typography, CardContent } from '@material-ui/core';
// import FileCopyIcon from '@material-ui/icons/FileCopy';
// import InvFriend from '../components/InvFriend';
import moment from 'moment';
import TabControl from '../components/LoginTabControl'
import { useTodoContext } from '../utils/GlobalState';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
}));

export default function Event({ match }) {
  const classes = useStyles();

  const inviteId = match.params.id
  const { refLink, setrefLink } = useTodoContext()
  useEffect(() => {
    setrefLink(inviteId)
  }, [])

  const [eventId, seteventId] = useState('')
  const [eventData, seteventData] = useState(false)
  // const [taskData, settaskData] = useState(false)
  // const [admin, setAdmin] = useState(false)

  useEffect(() => {
    API.getInvite(inviteId)
      .then(res => seteventId(res.data.eventId))
      .catch(err => {
        console.log(`error`, { err });
        throw new Error(err);
      })
  }, [])

  useEffect(() => {
    if (eventId) {
      API.getEvent(eventId)
        .then(res => seteventData(res.data))
        .catch(err => {
          console.log(`error`, { err });
        })
    }
  }, [eventId])

  // Invite page will route to event detail after successful signin/signup
  const history = useHistory()
  const onSuccess = () => {
    history.push(`/event/${eventId}`)
  }



  console.log(eventData);
  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12}>
          <Paper>
            <Card>
              <CardHeader
                title={`Welcome to Pland!`}
                subheader={`Your one stop shop for planning your next amazing event!`}
              />
              {eventData && <CardContent>
                <Typography
                  variant={'h6'}
                  gutterBottom
                >
                  You've been invited to: {eventData.title} !
                </Typography>
                <Typography>
                  Event Start: {moment(eventData.startDate).format('MM/DD/YYYY hh:mm a')}
                </Typography>
                <Typography>
                  Event End: {moment(eventData.endDate).format('MM/DD/YYYY hh:mm a')}
                </Typography>
                <Typography>
                  Please sign in or sign up below to continue
                </Typography>
              </CardContent>}
            </Card>
          </Paper>
        </Grid>
        <TabControl
          onSuccess={onSuccess}
        />
      </Grid>
    </main >
  )
}
