import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Typography, Button } from '@material-ui/core';
import MaterialTable from 'material-table';
import icons from './tableIcons';
import API from '../../utils/API'
import moment from 'moment'
// import { useTodoContext } from '../../utils/GlobalState';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    textAlign: 'center',
  },
  margin: {
    margin: '5px'
  }
}));

export default function GuestList(props) {
  console.log('Agenda Loading');
  const classes = useStyles();
  // const { eventData } = props;
  const [eventData, seteventData] = useState(props.eventData)
  // const { uid } = useTodoContext();

  const handleAdd = (data) => {
    console.log(data);

    API.addAgenda(eventData._id, {
      agendaItem: data.agendaItem,
      startTime: moment(data.startTime).set({ 'month': 1, 'day': 19, 'year': 1988 }).seconds(0),
      // status: data.status,
      // assignedTo: data.assignedTo,
      // dueDate: moment(data.dueDate).format('MM/DD/YYYY')
    }).then(updateTable)
  };
  // console.log(evt);
  const handleDelete = (data) => {
    // console.log(eventData, data);
    API.deleteAgenda(eventData._id, data._id).then(updateTable)
  };
  const updateTable = () => {

    API.getEvent(eventData._id)
      .then(
        res => seteventData(res.data),
        // console.log('guests', props.guests),
        // console.log(taskData),
      )
      .catch(err => {
        console.log(`error`, { err });
        // throw new Error(err);
      })
  };
  const updateTask = (newData) => {
    console.log(newData);

    API.updateAgenda(eventData._id, newData._id, newData).then(updateTable)
  }

  const sortedData = eventData.agenda.sort((a, b) => a.startTime - b.startTime);
  console.log(sortedData);


  const [state, setState] = useState({
    columns: [
      { title: 'ID', field: '_id', hidden: true },
      { title: 'Agenda Item', field: 'agendaItem' },
      { title: 'Start Time', field: 'startTime', type: 'time', defaultSort: 'asc' },
      // { title: 'Assigned To', field: 'assignedTo' },
      // { title: 'Due Date', field: 'dueDate', type: 'date', },
    ],
    // data: [
    //   { agendaItem: 'Test', time: '' }
    // ]
  });

  return (
    <div className={classes.content}>
      <Paper >
        <MaterialTable
          title='Agenda'
          icons={icons}
          columns={state.columns}
          data={sortedData}
          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  // newData.eventid = evt
                  handleAdd(newData)
                  resolve();
                }, 600);
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  // console.log(oldData, newData);
                  if (oldData) {
                    updateTask(newData)
                  }
                  resolve();
                }, 600);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  handleDelete(oldData)
                  resolve();
                }, 600);
              }),
          }}
        // options={{
        //   grouping: true
        // }}
        />
        {props.children}
      </Paper>
    </div>
  );
}
