import axios from 'axios';

export default {
  getBooks: function () {
    return axios.get('/api/books');
  },
  getBook: function (id) {
    return axios.get('/api/books/' + id);
  },
  deleteBook: function (id) {
    return axios.delete('/api/books/' + id);
  },
  saveBook: function (bookData) {
    return axios.post('/api/books', bookData);
  },

  //Events
  saveEvent: function (eventData) {
    return axios.post('/api/events', eventData);
  },
  getEvents: function () {
    return axios.get('/api/events');
  },
  getEvent: function (id) {
    return axios.get('/api/events/' + id);
  },
  updateEvent: function (eid, updatedEventData) {
    return axios.put(`/api/events/${eid}`, updatedEventData)
  },
  deleteEvent: function (id) {
    return axios.delete('/api/events/' + id);
  },
  getUsersEvents: function (uid) {
    return axios.get('/api/events/user/' + uid);
  },
  getGuestEvents: function (uid) {
    return axios.get('/api/events/guest/' + uid);
  },
  addGuest: function (id, guestObj) {
    return axios.put('/api/events/invited/' + id, guestObj)
  },
  updateGuest: function (eid, uid, status, userName) {
    return axios.put(`/api/events/update/${eid}/${uid}/${status}/${userName}`)
  },
  updateGuestAttending: function (eid, uid, num) {
    return axios.put(`/api/events/updatenum/${eid}/${uid}/${num}`)
  },
  addAgenda: function (eid, agendaObj) {
    return axios.put(`/api/events/agenda/new/${eid}`, agendaObj)
  },
  updateAgenda: function (eid, aid, agendaObj) {
    return axios.put(`/api/events/agenda/update/${eid}/${aid}`, agendaObj)
  },
  deleteAgenda: function (eid, aid) {
    return axios.delete(`/api/events/agenda/delete/${eid}/${aid}`)
  },

  //Tasks
  saveTask: function (taskData) {
    return axios.post('/api/tasks', taskData);
  },
  getTasks: function () {
    return axios.get('/api/tasks');
  },
  getTask: function (id) {
    return axios.get('/api/tasks/' + id);
  },
  deleteTask: function (id) {
    return axios.delete('/api/tasks/' + id);
  },
  getUserTask: function (uid, eid) {
    return axios.get('/api/tasks/user/' + uid + '/' + eid);
  },
  getEventTasks: function (eid) {
    return axios.get('/api/tasks/evt/' + eid);
  },
  updateTask: function (id, data) {
    return axios.put('/api/tasks/' + id, data);
  },
  completeTask: function (id, data) {
    return axios.put('/api/tasks/complete/' + id, data);
  },

  //Users
  saveUser: function (userData) {
    return axios.post('/api/user', userData);
  },
  getUsers: function () {
    return axios.get('/api/user');
  },
  getUser: function (id) {
    return axios.get('/api/user/' + id);
  },
  deleteUser: function (id) {
    return axios.delete('/api/user/' + id);
  },
  findFbUid: function (uid) {
    return axios.get('/api/user/fb/' + uid);
  },
  findUserName: function (userName) {
    return axios.get('/api/user/username/' + userName)
  },
  updateUserTheme: function (id, userData) {
    return axios.put('/api/user/theme/' + id, userData);
  },

  // //Agenda
  // saveAgenda: function (agendaData) {
  //   return axios.post('/api/agenda', agendaData);
  // },
  // getAgendas: function () {
  //   return axios.get('/api/agenda');
  // },
  // getAgenda: function (id) {
  //   return axios.get('/api/agenda/' + id);
  // },
  // deleteAgenda: function (id) {
  //   return axios.delete('/api/agenda/' + id);
  // },
  //Invite
  saveInvite: function (InviteData) {
    return axios.post('/api/Invite', InviteData);
  },
  getInvites: function () {
    return axios.get('/api/Invite');
  },
  getInvite: function (id) {
    return axios.get('/api/Invite/' + id);
  },
  deleteInvite: function (id) {
    return axios.delete('/api/Invite/' + id);
  },
};
