import Login from '../Login'
import SignUp from '../SignUp'
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `nav-tab-${index}`,
        'aria-controls': `nav-tabpanel-${index}`,
    };
}

function LinkTab(props) {
    return (
        <Tab
            component='a'
            onClick={(event) => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
}));

export default function NavTabs(props) {
    const { onSuccess } = props
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (

        <div className={classes.content}>
            <div className={classes.root}>
                <Paper className={classes.root}>
                    <Tabs
                        variant='fullWidth'
                        indicatorColor='primary'
                        textColor='primary'
                        centered
                        value={value}
                        onChange={handleChange}
                        aria-label='nav tabs example'
                    >
                        <LinkTab label='Login' href='/login' {...a11yProps(0)} />
                        <LinkTab label='Sign Up' href='/signUp' {...a11yProps(1)} />
                    </Tabs>
                </Paper>
                <TabPanel value={value} index={0}>
                    <Login
                        onSuccess={onSuccess}
                    />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <SignUp
                        onSuccess={onSuccess}
                    />
                </TabPanel>
            </div>
        </div>
    );
}



// const useStyles = makeStyles((theme) => ({
//     // necessary for content to be below app bar
//     toolbar: theme.mixins.toolbar,
//     content: {
//         flexGrow: 1,
//         padding: theme.spacing(3),
//     },
// }));

// const classes = useStyles();

// <main className={classes.content}>
// <div className={classes.toolbar} />

// </main>