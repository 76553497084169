import React, { useState } from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';

export default function SwitchesGroup(props) {
  const [state, setState] = props.stateTools

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Choose Widget's</FormLabel>
      <FormGroup>
        <FormControlLabel
          control={<Switch checked={state.task} onChange={handleChange} name="task" />}
          label="Task List"
        />
        <FormControlLabel
          control={<Switch checked={state.agenda} onChange={handleChange} name="agenda" />}
          label="Agenda"
        />
        <FormControlLabel
          control={<Switch checked={state.mealPlan} onChange={handleChange} name="mealPlan" />}
          label="Meal Planner"
        />
      </FormGroup>
      <FormHelperText>Have fun</FormHelperText>
    </FormControl>
  );
}