import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Typography, Button } from '@material-ui/core';
import MaterialTable from 'material-table';
import icons from './tableIcons';
import API from '../../utils/API'
// import Event from '../../../../models/event'
// import moment from 'moment'
import { useTodoContext } from '../../utils/GlobalState';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    textAlign: 'center',
  },
  margin: {
    margin: '2px'
  },
  justify: {
    justifyContent: 'center'
  }
}));

export default function GuestList(props) {
  console.log('Guest List Loading');
  const classes = useStyles();
  const { eventData } = props;
  const [guestData, setGuestData] = useState(eventData.guests)
  const { uid, userInfo, refLink, setrefLink } = useTodoContext();

  useEffect(() => {
    // check for refLink
    if (refLink && guestData) {
      // if guest is not on the list, add them
      // debugger
      const match = guestData.find(guest => guest.uid === uid)
      if (!match) {
        // add user to the array
        const { userName } = userInfo
        API.addGuest(eventData._id, {
          userName,
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          uid,
          numAttending: 1,
          status: "Undecided"
        })
          .then(res => { })
          .catch(err => console.log('err in guest list' + { err }))
      }
      // after adding guest to list or not, we should clear the reflink global state
      setrefLink('')
    }



  }, [guestData])

  console.log('====================================');
  console.log({ eventData });
  // console.log('====================================');
  console.log('====================================');
  console.log({ guestData });


  const updateGuest = async (data) => {
    // console.log(userInfo);
    await API.updateGuest(eventData._id, uid, data, userInfo.userName)
    const resp = await API.getEvent(eventData._id)
    const { data: d } = resp
    setGuestData(d.guests)
  }

  const updateGuestNum = async (data) => {
    // console.log(data);
    // console.log(uid);
    await API.updateGuestAttending(eventData._id, uid, data)
    const resp = await API.getEvent(eventData._id)
    const { data: d } = resp
    setGuestData(d.guests)
  }

  const [state, setState] = useState({
    columns: [
      { title: 'Guest', field: 'userName', editable: 'never' },
      { title: 'Status', field: 'status', editable: 'never' },
      { title: '# Attending', field: 'numAttending', type: 'numeric' }
      // { title: 'Assigned To', field: 'assignedTo' },
      // { title: 'Due Date', field: 'dueDate', type: 'date', },
    ]
  });

  //   let arr = [
  //     { name:"string 1", value:"this", other: "that" },
  //     { name:"string 2", value:"this", other: "that" }
  // ];

  // let obj = arr.find(o => o.name === 'string 1');

  // console.log(obj);


  //console.log({ guestData, userInfo });



  // take userInfo.userName
  // find index of user in guestDat
  // find status from guestData
  // if status is not undecided, collapse accordion

  // useEffect(() => {
  //   if (guestData) {
  //     let obj = guestData.find(g => g.userName === userInfo.userName);
  //     if (obj.status && obj.status !== "Undecided") {
  //       setExpanded(false)
  //     }
  //   }
  // }, [guestData])

  const [expanded, setExpanded] = useState('panel1');
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };




  return (
    <div className={classes.content}>
      <Paper >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Paper>
              <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                >
                  <Typography className={classes.heading}>Attending This Event?</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.justify}  >
                  <Button className={classes.margin} variant="contained" color="primary" onClick={() => { updateGuest('Going') }}>
                    Going! 😀
                    </Button>
                  <Button className={classes.margin} variant="contained" color="secondary" onClick={() => { updateGuest('Declined') }}>
                    Decline 😞
                    </Button>
                  <Button className={classes.margin} variant="contained" onClick={() => { updateGuest('Maybe') }}>
                    Maybe! 🤷‍♂️
                    </Button>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Paper>
          </Grid>
        </Grid>

        <MaterialTable
          title='Guests'
          icons={icons}
          columns={state.columns}
          data={guestData}
          options={{
            grouping: true
          }}
          editable={{

            onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  if (oldData) {
                    console.log(newData);
                    updateGuestNum(newData.numAttending)
                  }
                  resolve();
                }, 600);
              }),

          }}
        />
        {props.children}
      </Paper>
    </div>
  );
}
