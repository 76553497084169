import React, { useEffect, useState } from "react";
import { Button, CircularProgress } from "@material-ui/core";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CheckIcon from '@material-ui/icons/Check';
import copy from 'clipboard-copy'
import api from '../../utils/API'
import { useTodoContext } from '../../utils/GlobalState';


export default function InvFriend(props) {
    const { eventData } = props
    const { uid } = useTodoContext()
    const [invURl, setinvURl] = useState('')
    const [clicked, setclicked] = useState(false)

    const copyInvUrl = (url) => {
        // console.log('copy');
        copy(url)
        // console.log('copy');
        copy(url)
        setclicked(true)
    }

    useEffect(() => {
        if (uid) {
            // Gets invite, creates if needed
            api.saveInvite({
                eventId: eventData._id,
                invitee: uid
            })
                .then(({ data }) => setinvURl(`${window.location.origin}/invite/${data._id}`))
                .catch(err => console.error(err))
        }
    }, [uid])

    // useEffect(() => {
    //     setclicked(true)
    // }, [invURl])

    return (
        <div>
            {
                (!invURl) ?
                    <CircularProgress />
                    :
                    ((clicked) ?
                        <Button variant='contained' startIcon={<CheckIcon />} color='primary' onClick={() => copyInvUrl(invURl)}>
                            Copied!
                        </Button>
                        :
                        <Button variant='contained' startIcon={<FileCopyIcon />} color='primary' onClick={() => copyInvUrl(invURl)}>
                            Copy Invite URL
                        </Button>)
            }
        </div>
    );
}
