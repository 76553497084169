import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleIcon from '@material-ui/icons/People';
// import MailIcon from '@material-ui/icons/Mail';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import EventIcon from '@material-ui/icons/Event';
import AddIcon from '@material-ui/icons/Add';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
// import firebase from '../../utils/firebase'
import { useTodoContext } from "../../utils/GlobalState";
// import EventDialog from '../EventDialog/EventDialog';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('lg')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));


function LeftNav(props) {
  const { window, handleDrawerToggle, mobileOpen } = props;
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const handleClick = (e) => {
    console.log(e);
    // handleDrawerToggle()
    history.push(`/${e}`)
  }

  const { userAuth, firebase } = useTodoContext();


  useEffect(() => {
    console.log(`userAuth? ${JSON.stringify(userAuth)}`)
  }, [userAuth])
  // const context = contextFunc()
  async function handleLogout() {
    await firebase.logout()
    console.log('Logout');
    history.push('/login')
  }
  // const Testing = (e) => {
  //   firebase.getCurrentUid()

  // }

  // useEffect(() => {
  //   if (!firebase.getCurrentUsername()) {
  //     console.log('false');
  //     setLoggedIn(false)
  //   } else {
  //     console.log('true');
  //     setLoggedIn(true)
  //   };
  // }, [])


  const drawer = (
    <div>
      <div className={classes.toolbar} >
        <img src={process.env.PUBLIC_URL + '/assets/50px.png'} /> - Alpha Testing
      </div>
      <Divider />
      <List>
        <ListItem button disabled={!userAuth} onClick={() => { handleClick('NewEvent') }}>
          <ListItemIcon><AddIcon /></ListItemIcon>
          <ListItemText primary={'New Event'} />
        </ListItem>
        <ListItem button disabled={!userAuth} onClick={() => { handleClick('events') }}>
          <ListItemIcon><EventIcon /></ListItemIcon>
          <ListItemText primary={'My Events'} />
        </ListItem>
        {/* <ListItem button disabled={!userAuth} onClick={() => { handleClick('friends') }}>
          <ListItemIcon><PeopleIcon /></ListItemIcon>
          <ListItemText primary={'Friends'} />
        </ListItem> */}

      </List>
      <Divider />
      <List>
        {userAuth ?
          <ListItem button onClick={() => {
            handleLogout()
          }}>
            <ListItemIcon><ExitToAppIcon /></ListItemIcon>
            <ListItemText primary={'Log Out'} />
          </ListItem>
          :
          <ListItem button onClick={() => { handleClick('login') }}>
            <ListItemIcon><LockOpenIcon /></ListItemIcon>
            <ListItemText primary={'Login'} />
          </ListItem>
        }
        {/* <ListItem button onClick={() => { Testing() }}>
          <ListItemIcon><ExitToAppIcon /></ListItemIcon>
          <ListItemText primary={'TestButton'} />
        </ListItem> */}

        {/* {['My Account', 'Logout'].map((text, index) => (
          <ListItem button onClick={() => { history.push(`/${text}`) }} key={text}>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))} */}
      </List>
    </div >
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <nav className={classes.drawer} aria-label="">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden smUp implementation="css">
        <Drawer

          container={container}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
}

export default LeftNav;