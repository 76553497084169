import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core/';
import TextField from '@material-ui/core/TextField';
import { DatePicker } from "@material-ui/pickers";
// import {  DatePicker, TimePicker } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import API from '../utils/API'
import { useTodoContext } from "../utils/GlobalState";
import moment from 'moment'
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from "react-router-dom";
import Moment from 'moment'
import WidgetSwitch from '../components/WidgetSwitches'


const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(5),
        },
        padding: 10,
    },
    button: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '30ch',
    },
    timePicker: {
        width: 50
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing(),
    },
}));

export default function Event() {
    const classes = useStyles();
    const [eventName, seteventName] = useState(' ');
    const [eventLocation, seteventLocation] = useState(' ');
    const [eventDescription, seteventDescription] = useState(' ');
    const [selectedStartDate, handleStartDateChange] = useState();
    const [selectedEndDate, handleEndDateChange] = useState();
    // const [selectedStartTime, handleStartTimeChange] = useState();
    // const [selectedEndTime, handleEndTimeChange] = useState();
    const { uid, userInfo, emailVerf } = useTodoContext();
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const history = useHistory();

    // const handleClick = () => {
    //     setOpen(true);
    // };


    const [widgetState, setwidgetState] = useState({
        task: true,
        agenda: true,
        mealPlan: true,
    });






    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    // console.log({ uid, userInfo, emailVerf });


    const handleSave = (event) => {
        event.preventDefault()
        const { userName } = userInfo
        let payload = {
            title: eventName,
            owner: uid,
            location: eventLocation,
            description: eventDescription,
            startDate:  Moment(selectedStartDate).format('MM/DD/YYYY'),
            endDate: Moment(selectedEndDate).format('MM/DD/YYYY'),
            guests: [
                    {
                        userName,
                        uid,
                        status: 'Going'
                    },
            ],
            invitees: [],
            selectedWidgets: Object.keys(widgetState).filter(w => widgetState[w])
        }
        if (eventLocation === undefined || eventName === undefined || eventDescription === undefined || eventLocation === "" || eventName === "" || eventDescription === "") {
            console.log("fail");
            setOpen(true);
            // setDisabled(true);
        } else {
            setDisabled(true);

            API.saveEvent(payload)
                .then(res => {
                    history.push(`/event/${res.data._id}`)
                })
                .catch(err => {
                    // console.log(`error`, { err });
                    throw new Error(err);

                })
        }
        // console.log("save button clicked", JSON.stringify(payload));
    }
    const handleCancel = (event) => {
        event.preventDefault()
        // console.log("Cancel button clicked");
        history.push('/events')
    }

    const eventNameChange = (event) => {
        seteventName(event.target.value);
    };
    const eventDescChange = (event) => {
        seteventDescription(event.target.value);
    };
    const eventLocationChange = (event) => {
        seteventLocation(event.target.value);
    };


    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            <Paper elevation={3}>
                <form className={classes.root} noValidate autoComplete="off">
                    <div className={classes.root}>
                        <Grid container spacing={3}>
                            {
                                (!emailVerf) ?
                                    <Grid item xs={12}>
                                        <Paper variant='outlined'>
                                            <Typography variant='h5' align='center' color='error' gutterBottom>
                                                You must verify your email before creating a new event!
                                            </Typography>
                                            <Typography align='center' gutterBottom>
                                                <Button variant="contained" align='center' color="primary">
                                                    Re-Send Email!
                                            </Button>
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                    : ''
                            }
                            <Grid item xs={12}>
                                <TextField
                                    id="standard-full-width"
                                    label="Event Name"
                                    error={eventName === undefined || eventName === ''}
                                    name="eventName"
                                    style={{ margin: 8 }}
                                    placeholder="Enter your event name here!"
                                    helperText="Ex: My Super Awesome-tacular Camping Trip!"
                                    fullWidth
                                    required
                                    margin="normal"
                                    onChange={eventNameChange}
                                // InputLabelProps={{
                                //     shrink: true,
                                // }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Event Description"
                                    error={eventDescription === undefined || eventDescription === ''}
                                    // id="margin-none"
                                    name="eventDescription"
                                    placeholder="Enter your event description here!"
                                    // className={classes.textField}
                                    fullWidth
                                    multiline
                                    required
                                    style={{ margin: 8 }}
                                    onChange={eventDescChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Event Location"
                                    error={eventLocation === undefined || eventLocation === ''}
                                    // id="margin-none"
                                    name="eventDescription"
                                    placeholder="Enter your event description here!"
                                    // className={classes.textField}
                                    fullWidth
                                    multiline
                                    required
                                    style={{ margin: 8 }}
                                    onChange={eventLocationChange}
                                />
                            </Grid>

                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <Grid item xs={12} md={4} lg={4}>
                                    <DatePicker
                                        // orientation="landscape"
                                        variant="inline"
                                        // id="margin-none"
                                        required
                                        autoOk
                                        minDate={new Date()}
                                        label="Start Date/Time"
                                        value={selectedStartDate}
                                        onChange={handleStartDateChange}
                                        style={{ margin: 8, width: '100%' }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} lg={4}>
                                    <DatePicker
                                        variant="inline"
                                        // id="margin-none"
                                        required
                                        autoOk
                                        minDate={selectedStartDate || new Date()}
                                        label="End Date/Time"
                                        value={selectedEndDate || selectedStartDate}
                                        onChange={handleEndDateChange}
                                        style={{ margin: 8, width: '100%' }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} lg={4}>
                                    <WidgetSwitch 
                                        stateTools={[widgetState, setwidgetState]}
                                    />
                                </Grid>

                            </MuiPickersUtilsProvider>

                        </Grid>

                    </div>
                </form>

                <Grid container spacing={1}>
                    <Grid item xs={1}>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <div className={classes.button}>
                            <Button
                                disabled={disabled}
                                variant="contained"
                                color="secondary"
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                disabled={disabled}
                                //|| !emailVerf
                                variant="contained"
                                color="primary"
                                onClick={handleSave}
                                startIcon={<SaveIcon />}
                                style={{ float: 'right' }}
                            >
                                Save
                            </Button>
                        </div>
                    </Grid>

                </Grid>
            </Paper>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message="Please Fill Out Required Fields!"
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </main >
    )
}
