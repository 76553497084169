import React from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
// import { useTodoContext } from "../utils/GlobalState";
import TabControl from '../components/LoginTabControl'


const useStyles = makeStyles((theme) => ({
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function SignIn() {

  // Invite page will route to event detail after successful signin/signup
  const history = useHistory()
  const onSuccess = () => {
    console.log('====================================');
    console.log('did this work? on success');
    console.log('====================================');
    history.push(`/events`)
  }

  const classes = useStyles();
  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />

      <TabControl
        onSuccess={onSuccess}
      />
    </main>
  );
}