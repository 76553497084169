import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import ButtonBase from '@material-ui/core/ButtonBase';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import moment from 'moment'


const useStyles = makeStyles({
    root: {
        maxWidth: 345,
    },
    cardAction: {
        display: 'block',
        textAlign: 'initial',
        width: '100%',
        height: '100%'
    }
});

export default function ImgMediaCard(props) {
    const classes = useStyles();
    const [event] = useState(props.id)
    const history = useHistory()
    // const [date, changeDate] = useState(new Date());
    const handleClick = (e) => {
        history.push(`/event/${event}`)
        // console.log(event);

    }

    return (
        <Grid item xs={12} md={6} lg={3}>
            <Card className={classes.root}>
                <CardActionArea>
                    <ButtonBase
                        className={classes.cardAction}
                        onClick={handleClick}
                    >
                        <CardMedia
                            component="img"
                            alt="Awesome Event"
                            height="140"
                            image="https://media.istockphoto.com/photos/camping-tent-in-a-camping-in-a-forest-by-the-river-picture-id911995140?k=6&m=911995140&s=612x612&w=0&h=U-yG-2eR8pOxLX_G8Eg9fDI1SOWYifxbb4BiiOhNNiI="
                            title="Awesome Event"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5">
                                {props.title}
                            </Typography>
                            <Typography variant="body1" color='textSecondary' gutterBottom>
                                Start: {moment(props.start).format('MM/DD/YYYY hh:mm a')}
                            </Typography>
                            <Typography variant="button" gutterBottom>
                                Description:
                        </Typography>
                            <Typography noWrap={true} variant="body1" color='textSecondary' gutterBottom>
                                {props.description}
                            </Typography>
                            <Typography variant="button" gutterBottom>
                                Location:
                            </Typography>
                            <Typography noWrap={true} variant="body1" color='textSecondary' gutterBottom>
                                {props.location}
                            </Typography>
                        </CardContent>
                    </ButtonBase>
                </CardActionArea>
                <CardActions>
                    {/* <Button size="small" color="primary">
                        Invite
                        </Button> */}
                    <Button size="small" data-id={props.id} onClick={handleClick}>
                        Details/Edit
        </Button>
                </CardActions>
            </Card>
        </Grid >
    );
}